import { Routes } from '@angular/router';
import { AuthGuardWithForcedLogin } from './auth/auth-guard-with-forced-login.service';
import { ErrorComponent } from 'ngx-q360-lib';
import { provideStates } from '@ngxs/store';
import { WorkItemState } from '@global-shared/store/work-item.state';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages',
    pathMatch: 'full',
  },
  { path: 'callback', redirectTo: 'pages', pathMatch: 'full' },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.routes').then((m) => m.routes),
    canActivate: [AuthGuardWithForcedLogin],
    providers: [provideStates([WorkItemState])],
  },
  {
    path: 'error',
    pathMatch: 'full',
    component: ErrorComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: ErrorComponent,
    data: { error: { status: 404 } },
  },
];
